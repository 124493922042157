import React, {useEffect, useRef, useState} from "react";
import {usePaymentWithLockContract, useBUSDContract, useMMProContract} from "../../hooks/useContracts";
import {useWeb3React} from "@web3-react/core";
import ErrorMessage from "../../components/ErrorMessage";
import {wei2eth} from "../../Standard/utils/common";
import Button from "../../Standard/components/Button";
import {getBigNumber} from "../../utils/getBigNumber";
import {BigNumber} from "ethers";
import styled from "styled-components";
import Spinner from "../../Standard/components/Spinner";
import {useBalanceOfBUSD, useBalanceOfMMPRO} from "../../hooks/useBalance";
import PayWithLockHeader from "../../components/PayWithLockHeader";
import CountdownTimer from "../../components/CountdownTimer";
import dayjs from "dayjs";

const SuccessModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #e5e5e51f;
  color: white;
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
  text-align: center;
`

const MainPage = () => {
  const {account} = useWeb3React()
  const PWLContract = usePaymentWithLockContract()
  const BUSDContract = useBUSDContract()
  const MMPROContract = useMMProContract()
  const {balance: mmproBalance} = useBalanceOfMMPRO()
  const {balance: busdBalance, balanceLoading, updateBalance} = useBalanceOfBUSD()

  const [mmproLimit, setMmproLimit] = useState(0)
  const [notEnoughMmproError, setNotEnoughMmproError] = useState(false)
  const [busd, setBusd] = useState('')
  const [busdLimit, setBusdLimit] = useState(0)
  const [tsxLoading, setTsxLoading] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [remainingLimit, setRemainingLimit] = useState(0)
  const [tsxError, setTsxError] = useState(false)
  const [lock, setLock] = useState(0)
  const [timer, setTimer] = useState(0)
  const [isTimerStart, setIsTimerStart] = useState(false)
  const [unlockAt, setUnlockAt] = useState(0)
  const [isUnlockReady, setIsUnlockReady] = useState(false)
  const [mmproLocked, setMmproLocked] = useState(0)
  const successModalTimer = useRef()

  async function getBusdLimit() {
    const busdLimit = await PWLContract.methods.busdLimit().call()
    setBusdLimit(busdLimit / Math.pow(10, 18))
  }

  async function getLimit() {
    const mmproToLocked = await PWLContract.methods.mmproToLock().call()
    setMmproLimit(mmproToLocked / Math.pow(10, 18))
  }

  async function checkApprove() {
    if (account) {
      const busdCountApproved = await BUSDContract.methods.allowance(account, '0xed28e0E916c7fca4DF23399f6441514c09725692').call()
      const mmproCountApproved = await MMPROContract.methods.allowance(account, '0xed28e0E916c7fca4DF23399f6441514c09725692').call()
      const weiBusd = getBigNumber(busd)

      if (+busdCountApproved < +weiBusd) {
        const APPROVE_TRANS = BigNumber.from("115792089237316195423570985008687907853269984665640564039457584007913129639935");
        await BUSDContract.methods.approve('0xed28e0E916c7fca4DF23399f6441514c09725692', APPROVE_TRANS).send({from: account})
      }

      if (+mmproCountApproved < +mmproLimit) {
        const APPROVE_TRANS = BigNumber.from("115792089237316195423570985008687907853269984665640564039457584007913129639935");
        await MMPROContract.methods.approve('0xed28e0E916c7fca4DF23399f6441514c09725692', APPROVE_TRANS).send({from: account})
      }

      return true
    }
  }

  async function sendTransaction() {
    setTsxLoading(true)
    if (busd) {
      const weiBusd = getBigNumber(busd)
      try {
        const approveRes = await checkApprove()
        if (approveRes) {
          PWLContract.methods.pay(weiBusd).send({from: account})
            .then((res: any) => {
              if (res.status) {
                setTsxLoading(false)
                showSuccessTransaction()
                updateBalance()
                setBusd('')
                setIsTimerStart(true)
              }
            })
        }
      } catch (e) {
        setTsxError(true)
      }
    }
  }

  async function calculateBusdLimit() {
    if (busdLimit >= 0 && account) {
      const response = await PWLContract.methods.userPayment(account).call()
      const limit = +busdLimit - +wei2eth(response.busdPayed)
      setRemainingLimit(limit)
      setUnlockAt(response.unlockAt)
      setMmproLocked(response.mmproLocked)
    }
  }

  async function showSuccessTransaction() {
    setSuccessModal(true)

    // @ts-ignore
    successModalTimer.current = setTimeout(() => {
      setSuccessModal(false);
    }, 7000);
  }

  async function getDuration() {
    const time = await PWLContract.methods.lockDuration().call()
    setTimer(time)
  }

  async function getLock() {
    const lock = await PWLContract.methods.mmproToLock().call()
    setLock(lock / Math.pow(10, 18))
  }

  async function withdrawLockedTokens() {
    setTsxLoading(true)
    await PWLContract.methods.withdrawLocked().send({from: account}).then((res: any) => {
      if (res.status) {
        setTsxLoading(false)
      }
    })
  }

  const mmproEnoughCheck = () => {
    // Pass the check
    setNotEnoughMmproError(false)
    // if (mmproBalance && mmproLimit > +mmproBalance / Math.pow(10, 18)) {
    //   setNotEnoughMmproError(true)
    // } else {
    //   setNotEnoughMmproError(false)
    // }
  }

  useEffect(() => {
    getLimit()
    getBusdLimit()
    getLock()
    getDuration()
    calculateBusdLimit()
  }, [account, busdLimit])

  useEffect(() => {
    if (isTimerStart) {
      const interval = setInterval(() => {
        setTimer(timer => timer - 1)
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isTimerStart])

  useEffect(() => {
    mmproEnoughCheck()
  }, [mmproBalance, mmproLimit])

  useEffect(() => {
    const timestampDayjs = dayjs(unlockAt * 1000);
    const nowDayjs = dayjs();

    if (timestampDayjs.isBefore(nowDayjs)) {
      setIsUnlockReady(true)
    } else {
      setIsUnlockReady(false)
    }
  }, [unlockAt])

  const balanceMessage = balanceLoading ? "Loading..." : `Available: ${wei2eth(busdBalance).toFixed(2)} BUSD`

  return (
    <div className="w-6/12 items-center justify-center flex-col">
      <div>
        <PayWithLockHeader/>
        {tsxError ? <ErrorMessage type='primary' text={'Ooops... Something get wrong'}/>
          :
          <div className="px-4">
            {notEnoughMmproError ?
              <ErrorMessage type={'primary'} text={`Not enough MMPRO, balance must be greater than ${mmproLimit}`}/>
              :
              <>
                {/*<div>*/}
                {/*  {balanceMessage}*/}
                {/*  <div>Remaining limit: {remainingLimit} BUSD</div>*/}
                {/*  <div>We will lock {lock} MMPRO, for {(timer / (3600 * 24)).toFixed(0)} days</div>*/}
                {/*  <div className="mt-10 mb-10">*/}
                {/*    <label htmlFor="BUSD" className="block text-sm font-medium text-white">*/}
                {/*      BUSD Amount*/}
                {/*    </label>*/}
                {/*    <div className="mt-1">*/}
                {/*      <input*/}
                {/*        type="number"*/}
                {/*        name="BUSD"*/}
                {/*        id="BUSD"*/}
                {/*        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md h-10 p-4 text-black"*/}
                {/*        placeholder={wei2eth(busdBalance).toFixed(2)}*/}
                {/*        value={busd}*/}
                {/*        onChange={ev => setBusd(ev.target.value)}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <Button onClick={sendTransaction} className='w-full flex align-center justify-center'*/}
                {/*          children={tsxLoading ? <Spinner/> : 'Buy BITPAY and lock MMPRO tokens'} uppercase=''*/}
                {/*          disabled={false}/>*/}
                {/*  {busd &&*/}
                {/*    <div className="mt-5 text-center"> You will get {(+busd / 6.375).toFixed(2)} BITPAY Shares</div>}*/}
                {/*  {successModal && <SuccessModal>Transaction was successful</SuccessModal>}*/}
                {/*</div>*/}
                {
                  isUnlockReady
                    ?
                      mmproLocked > 0 ?
                        <div className={'p-4 flex items-center justify-center flex-col'}>
                          <div>Now you can claim your tokens, thank you for your participating!</div>
                          <div>MMPRO Locked: {mmproLocked / Math.pow(10, 18)}</div>
                          <Button onClick={withdrawLockedTokens} className='w-full flex align-center justify-center mt-6'
                                  children={tsxLoading ? <Spinner/> : 'Get locked tokens'} uppercase=''
                                  disabled={!(mmproLocked / Math.pow(10, 18))}/>
                        </div>
                      : "You dont have MMPRO tokens to unlock"
                    :
                    <CountdownTimer countdownTimestampMs={unlockAt} setIsUnlockReady={setIsUnlockReady}/>
                }
              </>
            }
          </div>
        }
      </div>
    </div>
  )
};

export default MainPage

