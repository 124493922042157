import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import './index.css'
import {getRemainingTimeUntilMsTimestamp} from '../../utils/countdownTimer';

const defaultRemainingTime = {
  seconds: '00',
  minutes: '00',
  hours: '00',
  days: '00'
}

type CountdownTimerPropType = {
  countdownTimestampMs: number
  setIsUnlockReady: Dispatch<SetStateAction<boolean>>
}

const CountdownTimerDefaultProps = {
  countdownTimestampMs: 0
}

const CountdownTimer = (props: CountdownTimerPropType) => {
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(props.countdownTimestampMs);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [props.countdownTimestampMs]);

  function updateRemainingTime(countdown: number) {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
  }

  return (
    <div className='flex items-center flex-col mt-5'>
      <div>We will unlock your tokens via</div>
      <div>
        <span className='mr-1'>{remainingTime.days}</span>
        <span className='mr-2'>days</span>
        <span className='mr-1'>{remainingTime.hours}</span>
        <span className='mr-2'>hours</span>
        <span className='mr-1'>{remainingTime.minutes}</span>
        <span className='mr-2'>minutes</span>
        <span className='mr-1'>{remainingTime.seconds}</span>
        <span>seconds</span>
      </div>
    </div>
  )
};

CountdownTimer.defaultProps = CountdownTimerDefaultProps

export default CountdownTimer