import Web3 from "web3";
import {useWeb3React} from "@web3-react/core";

import {
    getBUSDAddress,
    getMMProAddress,
} from "../Standard/utils/getAddress";

import {getPWLContractAddress} from "../contract/contstants";

import MMPRO from "../Standard/contracts/MMPRO.json";
import BUSD from "../Standard/contracts/Busd.json";
import PWL from "../contract/PaymentWithLock.json";
import getRpcUrl from "../Standard/utils/getRpcUrl";
import {useMemo} from "react";

const RPC_URL = getRpcUrl();
const httpProvider = new Web3.providers.HttpProvider(RPC_URL, {
    timeout: 10000,
});

export const useWeb3 = () => {
    const {library} = useWeb3React();
    return useMemo(() => {
        if (library) {
            return new Web3(library.currentProvider || httpProvider);
        } else {
            return new Web3(httpProvider);
        }
    }, [library]);
};

export const useContract = (abi, address) => {
    const web3 = useWeb3();
    return useMemo(() => new web3.eth.Contract(abi, address), [web3, abi, address]);
};

export const useBUSDContract = () => {
    const abi = useMemo(() => BUSD.abi, []);
    return useContract(abi, getBUSDAddress());
};

export const useMMProContract = () => {
    const abi = useMemo(() => MMPRO.abi, []);
    return useContract(abi, getMMProAddress());
};

export const usePaymentWithLockContract = () => {
  const abi = useMemo(() => PWL.abi, [])
  return useContract(abi, getPWLContractAddress())
}

