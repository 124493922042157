import React from "react";
import './index.css'
import {useWeb3React} from "@web3-react/core";

const PayWithLockHeader = () => {
    const {account} = useWeb3React()

    return (
      <div className="w-full py-4 text-left">
        <div className="staking-text-and-stats-flex flex items-center justify-between w-full mt-6 px-4 pb-4">
          <div className="staking-page-main-text-container text-left">
            <p className="mb-2 font-semibold md:text-5xl text-5xl">25% discount on Bitpay shares</p>
            <div className={'w-5/6'}>
              <p className={'font-semibold text-1xl mb-6'}>
                All holders with 20k MMPro tokens who will block them for 30 days will have the opportunity to invest up to $10k.
              </p>
              <p>
                The share price for such investors are 25% off previous round price and will be  $6.375 instead of $8.5 per share.
              </p>
            </div>
            {!account && <p className="font-semibold text-1xl mt-6">Connect your wallet.</p>}
          </div>
        </div>
      </div>
    )
};

export default PayWithLockHeader