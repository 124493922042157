/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React from "react";

import StandardAppContainer from "./Standard/StandardAppContainer";
import MainPage from "./pages/Main";
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
`

export const App = () => {
  return (
      <StandardAppContainer forcedLocale={'ru'} >
        <Wrapper>
          <MainPage />
        </Wrapper>
      </StandardAppContainer>
  );
};
